/* eslint-disable import/prefer-default-export */
export const linkResolver = (doc) => {
  // console.log('linkResolver', doc);
  if (!doc || !doc.type) {
    return null;
  }
  const lang = doc.lang.slice(0, -3);

  if (doc.type === 'project') {
    if (lang === 'en') {
      return `/proyectos/${doc.uid}`;
    }
    return `/${lang}/proyectos/${doc.uid}`;
  }

  if (doc.type === 'project_listing_page') {
    if (lang === 'en') {
      return '/proyectos';
    }
    return `/${lang}/proyectos`;
  }

  if (doc.type === 'commissions_page') {
    if (lang === 'en') {
      return '/comisiones';
    }
    return `/${lang}/comisiones`;
  }

  if (doc.type === 'donaciones') {
    if (lang === 'en') {
      return '/donaciones';
    }
    return `/${lang}/donaciones`;
  }

  if (doc.type === 'about') {
    if (lang === 'en') {
      return '/acerca_de';
    }
    return `/${lang}/acerca_de`;
  }

  if (doc.type === 'news_landing') {
    if (lang === 'en') {
      return '/noticias';
    }
    return `/${lang}/noticias`;
  }

  if (doc.type === 'news') {
    if (lang === 'en') {
      return `/noticias/${doc.uid}`;
    }
    return `/${lang}/noticias/${doc.uid}`;
  }

  if (doc.type === 'contact') {
    if (lang === 'en') {
      return '/contacto';
    }
    return `/${lang}/contacto`;
  }

  // Homepage route fallback
  return '/';
};
