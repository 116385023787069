import React, { useContext, useMemo, useState } from 'react';

const GlobalContext = React.createContext({});

export function GlobalStateProvider({ children }) {
  const [homepageIntroWatched, homepageIntroWatchedSet] = useState(false);
  const value = useMemo(() => ({
    homepageIntroWatched,
    homepageIntroWatchedSet,
  }), [homepageIntroWatched]);
  return (
    <GlobalContext.Provider value={value}>
      {children}
    </GlobalContext.Provider>
  );
}

export function useGlobalState() {
  const state = useContext(GlobalContext);
  return state;
}
