// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-source-prismic-graphql-components-preview-page-js": () => import("./../node_modules/gatsby-source-prismic-graphql/components/PreviewPage.js" /* webpackChunkName: "component---node-modules-gatsby-source-prismic-graphql-components-preview-page-js" */),
  "component---src-pages-404-jsx": () => import("./../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-templates-about-page-jsx": () => import("./../src/templates/AboutPage.jsx" /* webpackChunkName: "component---src-templates-about-page-jsx" */),
  "component---src-templates-commissions-page-jsx": () => import("./../src/templates/CommissionsPage.jsx" /* webpackChunkName: "component---src-templates-commissions-page-jsx" */),
  "component---src-templates-contact-page-jsx": () => import("./../src/templates/ContactPage.jsx" /* webpackChunkName: "component---src-templates-contact-page-jsx" */),
  "component---src-templates-donations-page-jsx": () => import("./../src/templates/DonationsPage.jsx" /* webpackChunkName: "component---src-templates-donations-page-jsx" */),
  "component---src-templates-homepage-jsx": () => import("./../src/templates/Homepage.jsx" /* webpackChunkName: "component---src-templates-homepage-jsx" */),
  "component---src-templates-news-detail-page-jsx": () => import("./../src/templates/NewsDetailPage.jsx" /* webpackChunkName: "component---src-templates-news-detail-page-jsx" */),
  "component---src-templates-news-landing-page-jsx": () => import("./../src/templates/NewsLandingPage.jsx" /* webpackChunkName: "component---src-templates-news-landing-page-jsx" */),
  "component---src-templates-project-detail-page-jsx": () => import("./../src/templates/ProjectDetailPage.jsx" /* webpackChunkName: "component---src-templates-project-detail-page-jsx" */),
  "component---src-templates-project-listing-page-jsx": () => import("./../src/templates/ProjectListingPage.jsx" /* webpackChunkName: "component---src-templates-project-listing-page-jsx" */)
}

