module.exports = [{
      plugin: require('../node_modules/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"defaultLang":"en-us","langs":["en-us","es-mx","pt-br"],"shortenUrlLangs":true,"repositoryName":"espaciov","previews":true,"path":"/preview","pages":[{"type":"Project","match":"/:lang?/proyectos/:uid?","path":"/proyecto/:uid?","component":"/opt/build/repo/src/templates/ProjectDetailPage.jsx","langs":["en-us","es-mx","pt-br"]},{"type":"Homepage","match":"/:lang?/","path":"/home","component":"/opt/build/repo/src/templates/Homepage.jsx","langs":["en-us","es-mx","pt-br"]},{"type":"Project_listing_page","match":"/:lang?/proyectos","path":"/proyectos","component":"/opt/build/repo/src/templates/ProjectListingPage.jsx","langs":["en-us","es-mx","pt-br"]},{"type":"Commissions_page","match":"/:lang?/comisiones","path":"/comisiones","component":"/opt/build/repo/src/templates/CommissionsPage.jsx","langs":["en-us","es-mx","pt-br"]},{"type":"Donaciones","match":"/:lang?/donaciones","path":"/donaciones","component":"/opt/build/repo/src/templates/DonationsPage.jsx","langs":["en-us","es-mx","pt-br"]},{"type":"News_landing","match":"/:lang?/noticias","path":"/noticias","component":"/opt/build/repo/src/templates/NewsLandingPage.jsx","langs":["en-us","es-mx","pt-br"]},{"type":"News","match":"/:lang?/noticias/:uid?","path":"/noticias/:uid?","component":"/opt/build/repo/src/templates/NewsDetailPage.jsx","langs":["en-us","es-mx","pt-br"]},{"type":"About","match":"/:lang?/acerca_de","path":"/acerca_de","component":"/opt/build/repo/src/templates/AboutPage.jsx","langs":["en-us","es-mx","pt-br"]},{"type":"Contact","match":"/:lang?/contacto","path":"/contact","component":"/opt/build/repo/src/templates/ContactPage.jsx","langs":["en-us","es-mx","pt-br"]}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/gatsby-icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"c97bbe859154d3a5d69df409f83f0ed7"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
