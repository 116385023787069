/* eslint-disable react/jsx-filename-extension */
/* eslint-disable import/prefer-default-export */
/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
//
import React from 'react';
import { registerLinkResolver } from 'gatsby-source-prismic-graphql';
import { linkResolver } from './src/utils/linkResolver';
import { GlobalStateProvider } from './src/contexts/globalState';

registerLinkResolver(linkResolver);

export const wrapRootElement = ({ element }) => (
  <GlobalStateProvider>
    {element}
  </GlobalStateProvider>
);
